<script setup lang="ts">
definePageMeta({ layout: 'login' });

const { successAfterLogin } = useAuth();

// const localePath = useLocalePath();
// const { formatLink } = useInternationalization(localePath);

useBreadcrumbs([
  {
    name: 'Login',
    path: '/login',
  },
]);
</script>

<script lang="ts">
export default { name: 'LoginPage' };
</script>

<template>
  <div class="login-wrapper">
    <AccountLoginForm @success="successAfterLogin()">
      <!-- <div class="flex items-center justify-end">
        <div class="text-sm">
          <NuxtLink
            :to="formatLink(`/account/recover`)"
            class="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {{ $t("recoveryPassword.forgotPassword") }}
          </NuxtLink>
        </div>
      </div> -->

      <!-- <template #action>
        <NuxtLink
          :to="formatLink(`/register`)"
          class="w-full flex justify-center py-2 px-4 border border-indigo-600 text-sm font-medium rounded-md text-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          {{ $t("account.signUp") }}
        </NuxtLink>
      </template> -->
    </AccountLoginForm>
  </div>
</template>

<style lang="scss" scoped>
.login-wrapper {
  width: 500px;

  @media (max-width: 767px) {
    width: 100%;
    padding: 0 16px;
  }
}

:deep(.login-form) {
  .dynamic-form-group__controls-input {
    padding-right: 0;
  }

  .button__wrapper {
    padding-right: 0;
  }
}
</style>
